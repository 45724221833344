.add-car-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #333;
}

.add-car-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.submit-button {
  background-color: #4a90e2;
  color: white;
  border-radius: 25px;
  padding: 10px 0;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #357ab7;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.image-grid {
  justify-content: center;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  color: #666;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.dropzone:hover {
  border-color: #4a90e2;
}

.dropzone-text {
  font-family: 'Roboto', sans-serif;
  color: #666;
}

.image-container {
  position: relative;
}

.image-preview {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  object-fit: cover;
}

.delete-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border-radius: 50%;
  padding: 5px;
}

.color-selection-box {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.color-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.color-circle:hover {
  transform: scale(1.1);
}

.selected {
  border: 3px solid #4a90e2;
}
