.color-selection-container {
    padding: 10px;
    text-align: center;
  }
  
  .color-circle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }
  
  .color-circle:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }
  
  .color-circle.selected {
    border: 3px solid #007BFF;
    transform: scale(1.15);
  }
  
  