/* Estilo principal do container */
.containerStyle {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Título principal */
  .titleStyle{
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Título das seções como "Cor" e "Imagens" */
  .sectionTitleStyle {
    margin-top: 20px;
    font-weight: bold;
    color: #666;
  }
  
  /* Container para os botões de ação */
  .buttonContainerStyle {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  