.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  border-radius: 10px;
}

.dropzone:hover {
  border-color: #007BFF;
}

.dropzone-text {
  font-size: 16px;
  color: #777;
}

.image-grid {
  margin-top: 20px;
}

.image-container {
  position: relative;
}
.image-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.image-wrapper:hover {
  transform: scale(1.05);
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  display: none;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.image-wrapper:hover .delete-button {
  display: block;
}

.dropzone {
  transition: background-color 0.3s ease;
}

