/* Estilos gerais do rodapé */
.footer {
    background-color: #000;
    color: #fff;
    font-family: 'Roboto', sans-serif;
  }
  
  .footer-logo {
    width: 150px;
    border-radius: 8px;
  }
  
  .footer-tagline {
    font-size: 0.9rem;
    color: #ccc;
  }
  
  .footer-title {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  
  .footer-text {
    font-size: 0.9rem;
    color: #ccc;
    line-height: 1.6;
  }
  
  .footer-link {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  .footer-socials {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .footer-icon {
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .footer-icon:hover {
    color: #bbb;
  }
  
  .footer-bottom {
    background-color: #111;
    text-align: center;
    border-top: 1px solid #222;
    padding: 15px 0;
    font-size: 0.85rem;
    color: #ccc;
  }
  
  /* Mobile ajustes */
  @media (max-width: 768px) {
    .footer-title, .footer-text {
      text-align: center;
    }
  
    .footer-socials {
      justify-content: space-around;
    }
  }
  